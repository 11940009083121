import { template as template_a56857ef4c8a4e06a0761fd43a6a258d } from "@ember/template-compiler";
const FKControlMenuContainer = template_a56857ef4c8a4e06a0761fd43a6a258d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
